import React, {useEffect, useState} from 'react';
import './cms.css'
import {connect} from 'react-redux';
import {getAllBanners, getPlaceHolderInfo, updatePlaceHolder} from '../../redux/actions/cmsAction'
import {Card, Button} from 'semantic-ui-react';
import {resetRequestMessage} from '../../redux/actions/otherActions';
import MyCard from "./MyCard";
import {uri as url} from '../../configs/server';
import ModalForMessages from "../../components/ModalForMessages";
import p from "../../permissionsConstants";
import HocModal from "../../components/HOC";
import Upload from "../../components/Upload";
const {cmsUpload} = p;

const rowItems = [
    {key: '10', text: 'Classic Lobby Cash', value: '1'},
    {key: '30', text: 'Open Lobby Cash', value: '2'},
    {key: '50', text: 'Spin Lobby ', value: '3'},
    {key: '60', text: 'Tournament', value: '4'},
    {key: '70', text: 'Leaderboard first', value: '5'},
    {key: '80', text: 'Leaderboard side', value: '6'},
];

function CmsPage({...redux}) {
    const {
        getAllBanners, allBanners, getPlaceHolderInfo, updatePlaceHolder,
        allPlaceHolders, requestMessage, resetRequestMessage, permissions
    } = redux;
    const [showModal, setShowModal] = useState(false);
    const [placeholderId, setPlaceholderId] = useState('');
    const [type, setType] = useState('');


    useEffect(() => {
        getAllBanners();
        getPlaceHolderInfo({placeHolderId: 1});
        getPlaceHolderInfo({placeHolderId: 2});
        getPlaceHolderInfo({placeHolderId: 3});
        getPlaceHolderInfo({placeHolderId: 4});
        getPlaceHolderInfo({placeHolderId: 5});
        getPlaceHolderInfo({placeHolderId: 6});

    }, []);

    useEffect(() => {
        let id;
        if (!!requestMessage && !requestMessage.error) {
            id = setTimeout(() => {
                resetRequestMessage('');
            }, 1000)
        }
        getAllBanners();
        getPlaceHolderInfo({placeHolderId: +requestMessage.id});
        setShowModal(false);
        return (() => {
            clearInterval(id)
        })
    }, [requestMessage]);


    const close = () => {
        setShowModal(false);
    };
    const upload = (type, id) => {
        setType(type);
        setPlaceholderId(id);
        setShowModal(true);
    };

    const save = (id, name) => {
        updatePlaceHolder({
            placeholderId: +id,
            imagesAndPriorities: [...allPlaceHolders[id]]
        }, name)

    };
    const closeM = () => {
        if (showModal) setShowModal(false);
        resetRequestMessage('');
    };
    const isIn = (content, id) => {
        const arr = allPlaceHolders[id] ? allPlaceHolders[id].filter((el) => el.content === content) : [];
        if (arr.length) {
            return {
                priority: arr[0].priority
            }
        }
    };
    const UploadPic = HocModal(Upload, {
        title: 'Upload',
        close,
        id: placeholderId,
        name: type
    });
    return (
        <div className="cms-content">
            {showModal && (
                <UploadPic/>
            )}
            {!!requestMessage ? (
                <ModalForMessages
                    error={requestMessage.error}
                    message={requestMessage.message}
                    close={closeM}
                    cms={true}
                />
            ) : null}
            <div className="page-title min-width">
                <h2>CMS</h2>

            </div>
            <div className="cms-content left-cms">
                {rowItems.map((el, index) => {
                    return (<Card className={'own'} key={index}>
                        <Card.Content header={el.text}/>
                        <Card.Content extra className={'extra-cms'}>
                            <Card.Group>
                                {
                                    allBanners.map((item, key) => {
                                        const temp = isIn(item.filename, el.value);
                                        return (<MyCard key={key}
                                                        src={`${url}images/${item.filename}`}
                                                        placeHolderId={el.value}
                                                        name={item.filename}
                                                        isIn={temp}
                                                        id={item.id}
                                        />)
                                    })
                                }
                            </Card.Group>
                        </Card.Content>
                        <Card.Content extra className='flex-end'>
                            <Button className='block belote' color={'green'} onClick={() => save(el.value)}>
                                Save
                            </Button>
                        </Card.Content>
                        {permissions && permissions[cmsUpload] ?
                            <Card.Content extra className='flex-end'>
                                <Button className='block belote-green' onClick={() => upload('banner', el.value)}>
                                    Upload Banner
                                </Button>
                                {/*<Button className='block' onClick={() => upload('html', item.value)}>*/}
                                {/*    Upload HTML*/}
                                {/*</Button>*/}
                            </Card.Content> : null}
                    </Card>)
                })}
            </div>
        </div>
    );
}

const mapStateToProps = ({cmsAllBanners, allPlaceHolders, requestMessage, userReducer}) => ({
    allBanners: cmsAllBanners,
    allPlaceHolders,
    requestMessage,
    permissions: userReducer.permissions
});

const mapDispatchToProps = {
    getAllBanners,
    getPlaceHolderInfo,
    updatePlaceHolder,
    resetRequestMessage
};
export default connect(mapStateToProps, mapDispatchToProps)(CmsPage);
