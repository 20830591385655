import React, { useState } from 'react';
import { GAME_NAMES } from '../../../../../constants/general';
import { getCurrencySymbol, setClass } from '../../../../../helpers/helpers/game';
import { getDateFromIso } from '../../../../../helpers/helpers/general';
import { useTranslation } from 'react-i18next';

function InfoReplay({ game, finishDate, startDate, status }) {
    const { kind, bet } = game;
    const isCompleted = status === 'COMPLETE';
    // const { time: startTime, year, day, monthNum } = getDateFromIso(Number(startDate));
    // const fullDateText = [day, monthNum, year].join('.');
    // const finishTime = getDateFromIso(Number(finishDate)).time;
    const [isPlayersRowOpen, setIsPlayersRowOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="history__game-info">
            <div className="history__game-info-row">
                <div className="history__game-info-cell">{t('Game Type')}</div>
                <div className="history__game-info-cell">{t(GAME_NAMES[kind])}</div>
            </div>
            {/*<div className="history__game-info-row">*/}
            {/*    <div className="history__game-info-cell">{t('Player 2')}</div>*/}
            {/*    <div className="history__game-info-cell">{opponentRemoteId}</div>*/}
            {/*</div>*/}
            {/*<div className="history__game-info-row">*/}
            {/*    <div className="history__game-info-cell">{t('Timer')}</div>*/}
            {/*    <div className="history__game-info-cell">*/}
            {/*        {time} {t('sec')}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="history__game-info-row">
                <div className="history__game-info-cell">{t('Bet')}</div>
                <div className="history__game-info-cell history__game-info-cell--green">
                    {getCurrencySymbol() + bet}
                </div>
            </div>
            <div className="history__game-info-row">
                <div className="history__game-info-cell">{t('Status')}</div>
                <div
                    className={`history__game-info-cell history__game-info-cell--${setClass(
                        isCompleted,
                        'green',
                        'orange'
                    )} history__game-info-cell--bold`}
                >
                    {status}
                    {/* {isWonGame ? t('Win') : t('Lose')} {getCurrencySymbol() + amount} */}
                </div>
            </div>
            <div className="history__game-info-row">
                <div className="history__game-info-cell">{t('Date')}</div>
                <div className="history__game-info-cell history__game-info-cell-opacity-eight">
                    {startDate}
                </div>
            </div>
            <div className="history__game-info-row">
                <div className="history__game-info-cell">{t('Duration')}</div>
                <div className="history__game-info-cell history__game-info-cell-opacity-eight">
                    {startDate + ' -- ' + finishDate}
                </div>
            </div>
        </div>
    );

    function togglePlayersRow() {
        setIsPlayersRowOpen(!isPlayersRowOpen);
    }
}

export default InfoReplay;
