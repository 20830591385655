import React, { useEffect, useState } from 'react';
import { getStoredPlayer, setClass } from '../../helpers/game';
import axios from 'axios';
import TableGameHistory from './components/TableGameHistory';
import Replay from './components/Replay/Replay';
import { useTranslation } from 'react-i18next';
// import { GLOBAL_URL } from '../../configs/api';
import { uri } from '../../configs/server';

function GameHistory({ isActive, gameId, gameData }) {
    const [historyList, setHistoryList] = useState([]);
    const [activeGame, setActiveGame] = useState({});
    const { t } = useTranslation();
    const [isFetching, setIsFetching] = useState(true);

    // useEffect(() => {
    //     const playerId = getStoredPlayer().id;

    //     axios(`https://garden-adjarabet.gamebox.rocks/c2c/history`, {
    //         method: 'POST',
    //         data: {
    //             // token: 'tokenForBo',
    //             token: 'DRez7D6TXko76bE',
    //             id: playerId,
    //             timeZone: (-1 * new Date().getTimezoneOffset()) / 60,
    //         },
    //     })
    //         .then((res) => {
    //             // console.log(JSON.parse(res.data.data.history), 'GameHistory');
    //             console.log('res.data.data', res.data.data);
    //             setIsFetching(false);
    //         })
    //         .catch((e) => {
    //             console.log('error->', e);
    //         });
    // }, [isActive]);

    useEffect(() => {
        if (isActive) {
            // axios(`${GLOBAL_URL}/c2c/bo/history/${gameId}`, {

            axios(`https://garden-adjarabet.gamebox.rocks/c2c/history/${gameId}`, {
                method: 'POST',
                data: {
                    // token: 'tokenForBo',
                    token: 'DRez7D6TXko76bE',
                    id: gameId,
                    timeZone: (-1 * new Date().getTimezoneOffset()) / 60,
                },
            })
                .then((res) => {
                    // console.log(JSON.parse(res.data.data.history), 'GameHistory');
                    if (!res.data.error) {
                        setHistoryList([res.data.data]);
                        setActiveGame(res.data.data.history);
                    }
                    setIsFetching(false);
                })
                .catch((e) => {
                    console.log('error->', e);
                });
        }
    }, [isActive]);

    return (
        <div className={`history ${setClass(isActive, 'history--active')}`}>
            <div className="history__columns">
                {isFetching ? (
                    <div
                        style={{ fontSize: '32px', position: 'absolute', top: '50%', left: '44%' }}
                    >
                        Loading...
                    </div>
                ) : historyList.length ? (
                    !!activeGame.id && (
                        <Replay
                            game={activeGame}
                            gameData={gameData[0]}
                            id={gameData[0]['player_id_host']}
                            isHistoryActive={isActive}
                        />
                    )
                ) : (
                    <div
                        className="history__column history__column--tables"
                        style={{ width: '100%' }}
                    >
                        <TableGameHistory date={t('Date')} />
                        <span
                            style={{
                                position: ' absolute',
                                left: '45%',
                                top: '50%',
                                fontSize: 'large',
                            }}
                        >
                            Your history is empty
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GameHistory;
