import React, { useEffect } from 'react';
import { getOneGame } from '../../redux/actions/gamesAction';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import './game.css';
import { setPlayer } from '../../redux/ducks/playerDucks';
import axios from 'axios';
import { uri } from '../../configs/server';

const gameCombs = {
    ALLCOMBS: 'All combinations',
    NO4NO100: 'Without 4 cards and 100',
    ONLYBR: 'Belote / Rebelote only',
};

const CurrentGame = ({ id, gameProps, ...redux }) => {
    // const currency = 'AMD';
    // const locale = 'AM';
    const { game, toggleType, setPlayer, getOneGame } = redux;
    useEffect(() => {
        getOneGame({ id });
    }, []);

    useEffect(() => {
        if (gameProps && gameProps[0] && gameProps[0]['player_id_host']) {
            const id = gameProps && gameProps[0] && gameProps[0]['player_id_host'];
            axios(`${uri}api/history/player`, {
                method: 'POST',
                data: {
                    token: 'tokenForBo',
                    id,
                },
            }).then((res) => {
                const {
                    currency,
                    locale,
                    ui_avatar,
                    ui_deck,
                    ui_table,
                    id,
                    remote_id: remoteId,
                } = res.data.data;
                setPlayer({
                    id,
                    settings: {
                        currency: currency,
                        language: locale,
                        avatarId: ui_avatar,
                        cardsColorId: ui_deck,
                        fieldColorId: ui_table,
                    },
                    remoteId: remoteId,
                });
            });
        }
    }, [gameProps]);

    return (
        <div className="game-info-content">
            {game && +game.id === +id ? (
                <>
                    <div className="page-title">
                        <h2> Current Game ID - {id}</h2>
                    </div>
                    <Table sortable celled fixed size="small">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {' '}
                                    Table ID:<span className="bold-data"> &nbsp;{game.id}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    {' '}
                                    Player ID Host:
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;
                                        {toggleType === 'Ajarabet'
                                            ? game.remote_id_host
                                            : game.player_id_host}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Player ID Guest:
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;
                                        {toggleType === 'Ajarabet'
                                            ? game.remote_id_guest
                                            : game.player_id_guest}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Winner ID:
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;
                                        {toggleType === 'Ajarabet'
                                            ? game.remote_id_winner
                                            : game.player_id_winner}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            {game.scene_title !== 'SPIN' ? (
                                <Table.Row>
                                    <Table.Cell>
                                        Bet Amount:
                                        <span className="bold-data">
                                            {' '}
                                            &nbsp;{game.props_amount}{' '}
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            ) : null}
                            {game.scene_title !== 'SPIN' ? (
                                <Table.Row>
                                    <Table.Cell>
                                        Max Win:
                                        <span className="bold-data">
                                            {' '}
                                            &nbsp;{game.props_amount_diff}
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            ) : null}
                            <Table.Row>
                                <Table.Cell>
                                    Points:{' '}
                                    <span className="bold-data"> &nbsp;{game.props_points}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Private:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;{game.props_private ? 'true' : 'false'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Capot2x:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;{game.props_capot2x ? 'true' : 'false'}{' '}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Davebeaver:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;{game.props_davebeaver}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Point Difference:{' '}
                                    <span className="bold-data"> &nbsp;{game.props_pointdiff}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Combs:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;{gameCombs[game.props_combs_title]}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Start Date:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;
                                        {moment(game.created_at, 'YYYY-MM-DD HH:mm:ss').isValid()
                                            ? game.created_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    End Date:{' '}
                                    <span className="bold-data">
                                        {' '}
                                        &nbsp;
                                        {moment(game.updated_at, 'YYYY-MM-DD HH:mm:ss').isValid()
                                            ? game.updated_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Status:{' '}
                                    <span className="bold-data"> &nbsp;{game.state_title}</span>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    Game Kind:{' '}
                                    <span className="bold-data">&nbsp;{game.kind_title}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Game Type:{' '}
                                    <span className="bold-data">
                                        &nbsp;
                                        {game.scene_title === 'SINGLE' ? 'CASH' : game.scene_title}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </>
            ) : (
                <h4> Loading ... </h4>
            )}
        </div>
    );
};

const mapStateToProps = ({ oneGameReducer, globalPlayerReducer }) => ({
    game: oneGameReducer[0],
    toggleType: globalPlayerReducer,
});

const mapDispatchToProps = {
    getOneGame,
    setPlayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentGame);
