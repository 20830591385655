import React, { useEffect, useState } from 'react';
import InfoReplay from './components/InfoReplay';
import HistoryRoom from '../../../RoomScene/HistoryRoom/HistoryRoom';
import HistoryPlayerButton from './components/HistoryPlayerButton';
import useToggle from '../../../../shared/hooks/general/useToggle';
import { setClass } from '../../../../helpers/game';
import { useForceUnmount } from '../../../../shared/hooks/general/useForceUnmount';

let intervalId;

/**
 * @return {null}
 */
function Replay({ game, gameData, isHistoryActive }) {
    
    const [replaySequence, setReplaySequence] = useState([]);
    const [replay, setReplay] = useState({});
    const [stepIndex, setStepIndex] = useState(0);
    const [round, setRound] = useState(1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRoundButtonClicked, setIsRoundButtonClicked] = useState(false);
    const [isInFullScreen, toggleFullScreen] = useToggle();
    const [fullscreenStateChanged] = useForceUnmount(isInFullScreen);
    const { created_at: startDate, updated_at: finishDate, state_title: status} = gameData

    const roundLastIndex =
        !!replaySequence[replaySequence.length - 1] &&
        replaySequence[replaySequence.length - 1].roundNumber;
    useEffect(() => {
        setReplaySequence(game.states);
        setStepIndex(0);
        setReplay({});
        if (isPlaying) {
            setIsPlaying(false);
        }
    }, [game.id]);
    useEffect(() => {
        if (!!round && isRoundButtonClicked) {
            const index = replaySequence.findIndex((el) => el.roundNumber === round);
            if (index >= 0) {
                setStepIndex(index);
            }
        }
    }, [round]);

    useEffect(() => {
        const currentReplay = replaySequence[stepIndex];
        if (!!currentReplay) {
            setReplay({});
            setReplay(currentReplay);
            setRound(currentReplay.roundNumber);
            if (stepIndex === replaySequence.length - 1 && isPlaying) {
                setIsPlaying(false);
            }
        }
    }, [replaySequence, stepIndex]);

    useEffect(() => {
        if (isPlaying) {
            setStepIndex((prev) => prev + 1);
            intervalId = setInterval(() => {
                setStepIndex((prev) => prev + 1);
            }, 1000);
        } else {
            if (!!intervalId) {
                clearTimeout(intervalId);
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        if (!isHistoryActive && isInFullScreen) {
            toggleFullScreen();
        }
    }, [isHistoryActive]);

    //prettier-ignore
    const playerButtonsProps = [
        { classPrefix: 'prev-item', isDisabled: stepIndex === 0, ariaLabel: 'prev', onClick: handlePrevRoundButton },
        { classPrefix: 'prev', isDisabled: stepIndex === 0, ariaLabel: 'prev', onClick: handlePrevButton },
        { classPrefix: 'prev-item', isDisabled: false, ariaLabel: 'play', onClick: handlePlayButton },
        {
            classPrefix: 'next',
            isDisabled: stepIndex === replaySequence.length - 1,
            ariaLabel: 'next',
            onClick: handleNextButton,
        },
        {
            classPrefix: 'next-item',
            isDisabled: stepIndex === replaySequence.length - 1,
            ariaLabel: 'next',
            onClick: handleNextRoundButton,
        },
    ];

    return replay.roomId ? (
        <div className="history__column history__column--video">
            <div
                className={`history__player ${setClass(
                    isInFullScreen,
                    'history__player--full-screen',
                    'history__player-screen--special-size'
                )}`}
            >
                <div className="history__player-screen">
                    {fullscreenStateChanged && (
                        <HistoryRoom
                            className="history__player-history-container"
                            replay={replay}
                            stepIndex={stepIndex}
                        />
                    )}
                </div>
                <div className="history__player-tab">
                    <div className="history__player-tab-cell history__player-tab-cell--rewind">
                        {playerButtonsProps.map(
                            ({ onClick, isDisabled, classPrefix, ariaLabel, index }) => (
                                <HistoryPlayerButton
                                    key={index}
                                    {...{ onClick, isDisabled, classPrefix, ariaLabel }}
                                />
                            )
                        )}
                    </div>
                    <div className="history__player-tab-cell">
                        <div className="history__player-tab-time">
                            {stepIndex + 1}/{replaySequence.length}
                        </div>

                        <div className="history__player-tab-time">{startDate}</div>
                        <button
                            onClick={toggleFullScreen}
                            type="button"
                            className="history__player-tab-button history__player-tab-button--full-screen"
                            aria-label="full screen"
                        />
                    </div>
                </div>
            </div>
            <InfoReplay game={game.states[0]} startDate={startDate} finishDate={finishDate} status={status}/>
        </div>
    ) : null;

    function handleNextButton() {
        if (stepIndex < replaySequence.length - 1) {
            if (isPlaying) {
                setIsPlaying(false);
            }
            setStepIndex((prev) => prev + 1);
            setIsRoundButtonClicked(false);
        }
    }

    function handlePrevButton() {
        if (stepIndex > 0) {
            if (isPlaying) {
                setIsPlaying(false);
            }
            setStepIndex((prev) => prev - 1);
            setIsRoundButtonClicked(false);
        }
    }

    function handlePrevRoundButton() {
        if (round > 1) {
            if (isPlaying) {
                setIsPlaying(false);
            }
            setRound((prev) => prev - 1);
        } else {
            if (round === 1) {
                setStepIndex(0);
            }
        }
        setIsRoundButtonClicked(true);
    }

    function handleNextRoundButton() {
        if (round < roundLastIndex) {
            setRound((prev) => prev + 1);
        } else {
            if (round === roundLastIndex) {
                setStepIndex(replaySequence.length - 1);
            }
        }
        if (isPlaying) {
            setIsPlaying(false);
        }
        setIsRoundButtonClicked(true);
    }

    function handlePlayButton() {
        if (stepIndex < replaySequence.length - 1) {
            setIsPlaying((prev) => !prev);
        }
    }
}

export default Replay;
