import Cookies from 'universal-cookie';
const cookies = new Cookies();
//export const uri = 'http://localhost:7777';
export const uri = '/'; // Production
// export const uri = 'https://bo-adjarabet.gamebox.rocks/'; // Production Dev
// export const uri = 'https://bo-dev.gamebox.rocks/'; // Development

export const getToken = () => cookies.get('authToken');
export const getLanguage = () => cookies.get('locale');
export const setAuthToken = (token) => {
    cookies.set('authToken', token, { path: '/' });
};
export const removeAuthToken = () => {
    cookies.remove('authToken');
};
