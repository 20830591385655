import React from 'react';
import { useTranslation } from 'react-i18next';

function TableGameHistory({ children, date }) {
    const { t } = useTranslation();

    return (
        <div className="history__row">
            <div className="history__row-date">
                <span className="history__row-date-text">{date}</span>
            </div>
            <div className="history__table">
                <div className="history__table-row history__table-row--header">
                    <div className="history__table-cell history__table-cell--game-type">{t('Game Type')}</div>
                    <div className="history__table-cell history__table-cell--status">{t('Status')}</div>
                    <div className="history__table-cell history__table-cell--game-start">{t('Start')}</div>
                    <div className="history__table-cell history__table-cell--score">{t('Score')}</div>
                    <div className="history__table-cell history__table-cell--type">{t('Type')}</div>
                    <div className="history__table-cell history__table-cell--button" />
                </div>
                <div className="history__table-rows history__table-rows--body">{children}</div>
            </div>
        </div>
    );
}

export default TableGameHistory;
