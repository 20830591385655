import React, { useState, useRef, useEffect } from 'react';
import { NOTES_COLORS_IDS } from '../../../../../../constants/game/ids';
import ColorButton from './components/ColorButton';
import { NOTES_COLORS_CLASSES } from '../../../../../../constants/game/classes';
import { getStoredPlayer, setClass } from '../../../../../../helpers/game';
import useOnClickOutside from '../../../../../../shared/hooks/general/useOnClickOutside';
import axios from 'axios';
// import { getTokenFromUrl } from '../../../../../../../api/helpers';
import { useGetPlayerNotes } from '../../../../../../shared/hooks/gameplay/useGetPlayerNotes';
import { connect } from 'react-redux';
import { setPlayerNotes } from '../../../../../../redux/ducks/playerDucks';
import { useTranslation } from 'react-i18next';
import { uri } from '../../../../../../configs/server';

const colorsIdList = Object.values(NOTES_COLORS_IDS);

function PopupNotesOpponent({ playerId, ...redux }) {
    const { notes, setPlayerNotes } = redux;
    const playerNotes = useGetPlayerNotes(notes, playerId);

    const [chosenColorId, setChosenColorId] = useState(NOTES_COLORS_IDS.NONE);
    const [noteText, setNoteText] = useState('');

    const [isPopupActive, setIsPopupActive] = useState(false);
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        isPopupActive && handlePopupCancelButton();
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (!!playerNotes) {
            const { playerNoteText, playerColorId } = playerNotes;
            setChosenColorId(playerColorId);
            setNoteText(playerNoteText);
        }
    }, [playerNotes]);

    return (
        <>
            <div className="blot-field__user-icon-message-add-popup" ref={ref}>
                <div className="blot-field__user-icon-message-add-popup-title">{t('Only visible for you')}</div>
                <form className="blot-field__user-icon-message-add-popup-form" action="">
                    <div className="blot-field__user-icon-message-add-popup-sub-title">{t('Make a note')}</div>
                    <textarea
                        className="blot-field__user-icon-message-add-popup-form-textarea"
                        placeholder={t('Enter your note')}
                        value={noteText}
                        onChange={handleNoteInput}
                    />
                </form>
                <div className="blot-field__user-icon-message-add-popup-color-container">
                    <div className="blot-field__user-icon-message-add-popup-sub-title">{t('Add a colour')}</div>
                    <ul className="blot-field__user-icon-message-add-popup-color-choice-list">
                        {colorsIdList.map((id) => (
                            <ColorButton
                                key={id}
                                colorId={id}
                                onClick={handleColorButtonClick}
                                isActive={Number(id) === Number(chosenColorId)}
                            />
                        ))}
                    </ul>
                </div>
                <div className="blot-field__user-icon-message-add-popup-btn-container">
                    <button
                        className="blot-field__user-icon-message-add-popup-btn blot-field__user-icon-message-add-popup-btn-cancel"
                        onClick={handlePopupCancelButton}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        className="blot-field__user-icon-message-add-popup-btn blot-field__user-icon-message-add-popup-btn-ok"
                        onClick={handlePopupConfirmButton}
                    >
                        {t('OK')}
                    </button>
                </div>
            </div>
            {!!playerNotes.playerNoteText && (
                <button className="blot-field__user-icon-btn blot-field__user-icon-btn-message" />
            )}
            <div className="blot-field__user-icon-message-popup blot-field__popup-background">
                <div className="blot-field__user-icon-message-name">{playerNotes.playerNoteText}</div>
            </div>
            <button
                className={`blot-field__user-icon-btn blot-field__user-icon-btn-color blot-field__user-icon-btn-color-${
                    NOTES_COLORS_CLASSES[playerNotes.playerColorId]
                }`}
            />
        </>
    );

    function handleColorButtonClick({ currentTarget: { id } }) {
        setChosenColorId(id);
    }

    function handleNoteInput({ currentTarget: { value } }) {
        if (value.length < 12) {
            setNoteText(value);
        }
    }

    // function handleAddNoteClick() {
    //     setIsPopupActive(true);
    //     setChosenColorId(playerNotes.playerColorId);
    //     setNoteText(playerNotes.playerNoteText);
    // }

    async function handlePopupConfirmButton() {
        const { note, color } = playerNotes;
        const confirmWithoutChoice = noteText === note && color === chosenColorId;
        if (!confirmWithoutChoice) {
            const res = await axios(uri + 'api/c2c/note', {
                method: 'POST',
                data: {
                    // sourceId: getStoredPlayer().id,
                    targetId: playerId,
                    color: chosenColorId,
                    note: noteText,
                    // token: getTokenFromUrl(),
                    token: "",
                },
            });

            if (!res.data.error) {
                setIsPopupActive(false);
                setPlayerNotes({ color: chosenColorId, note: noteText, id: playerId });
            }
        } else {
            setIsPopupActive(false);
        }
    }

    function handlePopupCancelButton() {
        setIsPopupActive(false);
    }
}

const mapStateToProps = ({ player: { notes } }) => ({
    notes,
});

const mapDispatchToProps = {
    setPlayerNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupNotesOpponent);
