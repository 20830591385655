import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Home from '../home/Home';
import SideBarMenu from '../../components/menu/Menu';
import Player from '../player/Player';
import User from '../user/User';
import Game from '../game/Game';
import CurrentPlayer from '../currentPlayer/CurrentPlayer';
import Setting from '../setting/Setting';
import Acl from '../aclManagment/ACL';
import Profile from '../profile/Profile';
import Tournament from '../tournament/Tournament';
import CreateTournament from '../tournament/createTournament/createTournament';
import OpenCurrentTournament from '../tournament/showTournament/OpenCurrentTournament';
import TournamentTemplate from '../tournament/TournamentTemplates';
import AllPrizeRules from '../tournament/AllPrizeRules';
import { leftDropDownMenuControl } from '../../redux/actions/otherActions';
import { connect } from 'react-redux';
import CmsPage from '../cms/CmsPage';
import { NoMatchPage } from '../404/NotFound';
import SpinList from '../spin/SpinList';
import SpinReport from '../spin/SpinReport';
import OpenCurrentSpin from '../spin/OpenCurrentSpin';
import OpenOneGame from '../game/OpenOneGame';
import moment from 'moment-timezone';
import Tickets from '../tickets/Tickets';
import BazarBlot from '../bazarBlot/BazarBlot';
import OpenOneBazar from '../bazarBlot/OpenOneBazar';

function MainPage({ history, ...redux }) {
    const { leftDropDownMenuControl, permissions, zone } = redux;
    const hasPermissions = (path) => {
        return permissions[path];
    };
    return (
        <div className="flex">
            <SideBarMenu />

            <div
                className={
                    history.location.pathname === '/admin' ? 'right-side home' : 'right-side'
                }
            >
                <Switch>
                    <Route
                        exact
                        path={'/admin/'}
                        render={() => {
                            if (hasPermissions('home')) {
                                return <Home />;
                            }
                            // return <NoMatchPage/>
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/players'}
                        render={() => {
                            if (hasPermissions('players')) {
                                return <Player />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/tickets'}
                        render={() => {
                            if (hasPermissions('tickets')) {
                                return <Tickets />;
                            }
                            return <NoMatchPage />;
                        }}
                    />

                    <Route
                        exact
                        path={'/admin/games'}
                        render={() => {
                            if (hasPermissions('games')) {
                                return <Game />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/bazar-blot'}
                        render={() => {
                            if (hasPermissions('games')) {
                                return <BazarBlot />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/tournaments'}
                        render={() => {
                            leftDropDownMenuControl('tournament');
                            if (
                                hasPermissions('tournamentList') ||
                                hasPermissions('createTournament') ||
                                hasPermissions('tournamentTemplate') ||
                                hasPermissions('tournamentPrizeTemplate')
                            ) {
                                return <Tournament />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/open/tournament/:id'}
                        render={(props) => {
                            if (hasPermissions('tournamentList')) {
                                return <OpenCurrentTournament {...props} />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/create-tournament'}
                        render={() => {
                            if (hasPermissions('createTournament')) {
                                return <CreateTournament />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/tournament-template'}
                        render={() => {
                            if (hasPermissions('tournamentTemplates')) {
                                return <TournamentTemplate />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/all-prize-rules'}
                        render={() => {
                            if (hasPermissions('tournamentPrizeTemplates')) {
                                return <AllPrizeRules />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/spinReport'}
                        render={() => {
                            if (hasPermissions('spin')) {
                                return <SpinReport />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/managment'}
                        render={() => {
                            if (
                                hasPermissions('acLGroups') ||
                                hasPermissions('aclResources') ||
                                hasPermissions('aclUsers')
                            ) {
                                return <Acl />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/users'}
                        render={() => {
                            if (hasPermissions('users')) {
                                return <User />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/settings'}
                        render={() => {
                            if (hasPermissions('settings')) {
                                return <Setting />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/spinList'}
                        render={() => {
                            if (hasPermissions('spin')) {
                                return <SpinList />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/cms'}
                        render={() => {
                            if (hasPermissions('cms')) {
                                return <CmsPage />;
                            }
                            return <Redirect to={'/404'} />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/open/spin/:id'}
                        render={(props) => {
                            if (hasPermissions('spin')) {
                                return <OpenCurrentSpin {...props} />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/profile'}
                        render={() => {
                            if (hasPermissions('profile')) {
                                return <Profile />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/players/:id'}
                        render={(props) => {
                            if (hasPermissions('players')) {
                                return <CurrentPlayer {...props} />;
                            }
                            return <NoMatchPage />;
                        }}
                    />

                    <Route
                        exact
                        path={'/admin/games/:gameId'}
                        render={(props) => {
                            if (hasPermissions('games')) {
                                return <OpenOneGame {...props} />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route
                        exact
                        path={'/admin/bazar/:id'}
                        render={(props) => {
                            if (hasPermissions('games')) {
                                return <OpenOneBazar {...props} />;
                            }
                            return <NoMatchPage />;
                        }}
                    />
                    <Route exact path={'*'} component={NoMatchPage} />
                </Switch>
            </div>
        </div>
    );
}

const mapStateToProps = ({ userReducer, userTimeZoneReducer }) => ({
    permissions: userReducer['permissions'],
    zone: userTimeZoneReducer,
});

const mapDispatchToProps = {
    leftDropDownMenuControl,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
